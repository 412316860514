import React from 'react';
import Loadable from 'react-loadable';
import {
  ComponentPreview,
  DontDo,
  IconItem,
  Link,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  Section,
  SectionSubhead,
} from '../../../components';
import pageHeroData from '../../../data/pages/guidelines.yml';
import styles from './styles/iconography.module.scss';

const IconSettings = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.IconSettings;
    return <Component {...props} />;
  },
});

const IconHelp = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.IconHelp;
    return <Component {...props} />;
  },
});

const IconSearch = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.IconSearch;
    return <Component {...props} />;
  },
});

const IconEdit = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.IconEdit;
    return <Component {...props} />;
  },
});

const IconShare = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.IconShare;
    return <Component {...props} />;
  },
});

const IconPlay = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.IconPlay;
    return <Component {...props} />;
  },
});

const IconAdd = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.IconAdd;
    return <Component {...props} />;
  },
});

const IconUser = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.IconUser;
    return <Component {...props} />;
  },
});

const IconMessages = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.IconMessages;
    return <Component {...props} />;
  },
});

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Iconography"
      designCode
      subnav="guidelines">
      <PageHero heroData={pageHeroData} tierTwo="Iconography" />

      <PageNavigation
        links={['Style', 'Metaphor', 'Color', 'Usage', 'Platform']}
      />

      <Section title="Style">
        <Paragraph>
          Our iconography style relies on our personality traits: <em>smart</em>{' '}
          and <em>reliable</em>.
        </Paragraph>
        <Paragraph>
          This icon style can be described as “approachable”. Hard shapes are
          softened for personality but never gratuitously rounded or bubbly to
          feel overly playful. Put another way: sports are fun, but we know
          athletes and coaches take their sport seriously.
        </Paragraph>
      </Section>

      <Section title="Metaphor">
        <Paragraph className="uni-padding--one--bottom">
          Supporting our predictable design principle, we strive to use industry
          standard metaphors.
        </Paragraph>

        <div
          style={{
            display: 'grid',
            gridGap: 'var(--space-three) var(--space-one-and-half)',
            gridTemplateColumns: 'repeat(auto-fill, 96px)',
            marginTop: 'var(--space-one)',
          }}>
          <IconItem iconComponent={<IconSettings />} displayName="Settings" />
          <IconItem iconComponent={<IconHelp />} displayName="Help" />
          <IconItem iconComponent={<IconSearch />} displayName="Search" />
          <IconItem iconComponent={<IconEdit />} displayName="Edit" />
          <IconItem iconComponent={<IconShare />} displayName="Share" />
          <IconItem iconComponent={<IconPlay />} displayName="Play" />
          <IconItem iconComponent={<IconAdd />} displayName="Add" />
          <IconItem iconComponent={<IconUser />} displayName="User" />
        </div>

        <Paragraph className="uni-padding--two--top">
          For unique features where an industry standard has not yet been
          established, clarity is the guiding principle.
        </Paragraph>
      </Section>

      <Section title="Color">
        <Paragraph>
          Our standard icons are glyphs and should be filled with a single solid
          color, presented without shadows.
        </Paragraph>

        <ComponentPreview name="IconColor" layout="row">
          <IconMessages
            size="large"
            color="contrast"
            className={styles.guidelinesIcons}
          />
          <IconMessages
            size="large"
            color="nonessential"
            className={styles.guidelinesIcons}
          />
          <IconMessages
            size="large"
            color="action"
            className={styles.guidelinesIcons}
          />
          <IconMessages
            size="large"
            color="confirmation"
            className={styles.guidelinesIcons}
          />
          <IconMessages
            size="large"
            color="warning"
            className={styles.guidelinesIcons}
          />
          <IconMessages
            size="large"
            color="critical"
            className={styles.guidelinesIcons}
          />
        </ComponentPreview>
      </Section>

      <Section title="Usage">
        <Paragraph>
          Our{' '}
          <Link href="/components/icon" isDesignCodeLink>
            icon
          </Link>{' '}
          component shows how categorizing icons guide how they should be used
          within an interface.
        </Paragraph>

        <SectionSubhead>Style</SectionSubhead>
        <Paragraph>
          It’s always best to prefer clarity over cleverness and style.
        </Paragraph>
        <DontDo
          dontText="be too playful or abrasive."
          doText="put clear meaning over style."
          imgFilename="iconography-style"
        />

        <SectionSubhead>Metaphor</SectionSubhead>
        <Paragraph>
          Icons without an established metaphor should be supported with text.
          Always use the correct icon with its intended meaning.
        </Paragraph>
        <DontDo
          dontText="use an existing metaphor to define a new icon."
          doText="use the right icon for the right metaphor."
          imgFilename="iconography-metaphor"
        />
      </Section>

      <Section title="Platform">
        <PlatformTable />
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
